var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("ics-page-login-header", {
        attrs: {
          "project-title": _vm.projectTitle,
          layout: [],
          "show-account": false
        }
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "partition-area" }, [
      _c("div", { staticClass: "el-card box-card is-always-shadow" }, [
        _c("div", { staticClass: "el-card__header" }, [
          _c("div", { staticClass: "clearfix" }, [
            _c("span", { staticClass: "box-card__title" }, [_vm._v("控件下载")])
          ])
        ]),
        _c("div", { staticClass: "el-card__body" }, [
          _c("ul", { staticStyle: { "list-style": "none" } }, [
            _c("li", [
              _c("a", { attrs: { href: "/template/CryptoKit.CFCPNGM.exe" } }, [
                _c("span", [_vm._v("CryptoKit.CFCPNGM.exe")])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }