var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-header", { staticClass: "header", attrs: { height: "48px" } }, [
    _c("h1", { staticClass: "logo" }, [
      _c("a", {
        staticClass: "logo-title",
        attrs: { href: _vm.consoleDomain || "/" }
      })
    ]),
    _c(
      "div",
      { staticClass: "logo-subtitle", staticStyle: { "margin-top": "-5px" } },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v(" " + _vm._s(_vm.projectTitle) + " ")
        ])
      ],
      1
    ),
    _vm.showAccount
      ? _c(
          "div",
          { staticClass: "account" },
          [
            _c(
              "router-link",
              {
                staticClass: "item-p account-link",
                attrs: {
                  tag: "a",
                  target: "_blank",
                  to: { name: "cfcaDownload" }
                }
              },
              [_c("span", [_vm._v("控件下载")])]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }