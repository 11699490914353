<template>
  <el-header class="header" height="48px">
    <h1 class="logo">
      <a :href="consoleDomain || '/'" class="logo-title" />
    </h1>
    <div class="logo-subtitle" style="margin-top: -5px;">
      <router-link to="/">
        {{ projectTitle }}
      </router-link>
    </div>
    <div v-if="showAccount" class="account">
      <router-link tag="a" target="_blank" :to="{name: 'cfcaDownload'}" class="item-p account-link">
        <span>控件下载</span>
      </router-link>
    </div>
  </el-header>
</template>
<script>
export default {
  name: 'PageLoginHeader',
  props: {
    isDot: {
      type: Boolean,
      default: true
    },
    maxNum: {
      type: Number,
      default: 99
    },
    logoImg: {
      type: String,
      default: ''
    },
    projectTitle: {
      type: String,
      default: '管理平台'
    },
    empName: {
      type: [String, Number],
      default: ''
    },
    consoleDomain: {
      type: String,
      default: ''
    },
    untreatedTodoNum: {
      type: Number,
      default: null
    },
    showAccount: {
      type: Boolean,
      default: true
    },
    unreadMessageNum: {
      type: Number,
      default: null
    },
    messageLink: {
      type: String,
      default: 'javascript:;'
    },
    layout: {
      type: Array,
      default () {
        return ['welcome', 'center', 'setting', 'todo', 'message', 'logout']
      }
    }
  },
  data () {
    return {
      loading: false,
      messageViewStatus: false
    }
  },
  watch: {
  },
  created () {
    // this.getEmpName()
  },
  methods: {
    logOut () {
      this.$emit('logout')
    },
  }
}
</script>
<style lang="less">
</style>
