<!-- login -->
<template>
  <div class="login-page">
    <ics-page-login-header :project-title="projectTitle" :layout="[]" :show-account="false" />
    <div class="partition-area">
      <div class="el-card box-card is-always-shadow">
        <div class="el-card__header">
          <div class="clearfix">
            <span class="box-card__title">控件下载</span>
          </div>
        </div>
        <div class="el-card__body">
          <ul style="list-style: none;">
            <li>
              <a href="/template/CryptoKit.CFCPNGM.exe">
                <span>CryptoKit.CFCPNGM.exe</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {cookie} from "../assets/js/config";
import IcsPageLoginHeader from '@/components/page-login-header'
export default {
  components: { IcsPageLoginHeader },
  data () {
    return {
      projectTitle: '',
      logonBackgroundMap: '',
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.logonBackgroundMap = result.logonPage.backgroundMap
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
}
</script>

<style scoped>
.box-card .clearfix span{
  font-size: 18px;
  color: #000000;
}
.el-card__body {
  padding: 10px 20px;
  font-size: 14px;
}
.box-card ul li .load {
  float: right;
  cursor: pointer;
  text-decoration: underline;
}
</style>
